
import { computed, defineComponent, ref } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import { useStore } from "vuex";
import moment from "moment";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const store = useStore();
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");

    const building = computed(() => store.getters.serverConfigUrl.building);
    const appVersion = process.env.VUE_APP_VERSION || "1.0.0";
    return {
      footerWidthFluid,
      building,
      apiUrl,
      moment,
      appVersion,
    };
  },
});
