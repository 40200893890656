
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, inject, onUnmounted, onMounted } from "vue";
import moment from "moment";
import { Socket } from "socket.io-client";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  setup() {
    const notifications = ref<any[]>([]);
    const parentId = window.localStorage.getItem("parentId");
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const socket: Socket = inject("socket") as Socket;

    const SchoolarYears = ref([
      {
        _id: "",
        name: "",
      },
    ]);
    ApiService.post("/schoolaryears/filter", {
      query: { $query: {}, $orderby: { end: -1 } },
    }).then((res) => {
      const data = res.data.map((sc) => {
        return {
          ...sc,
          name:
            new Date(sc["start"]).getFullYear() +
            "-" +
            new Date(sc["end"]).getFullYear(),
        };
      });
      SchoolarYears.value = data;
    });

    const getHomeworkLink = async (id: string): Promise<string> => {
      try {
        const response = await ApiService.get(`/lms/student/homework/${id}`);
        return "/devoirs/" + response.data.subject._id + "/exercise/" + id;
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          return `/resource-deleted?type=homework`;
        }
        return "/devoirs";
      }
    };

    const getCourseLink = async (id: string): Promise<string> => {
      try {
        const response = await ApiService.get(`/lms/student/course/${id}`);
        return "/courses/" + response.data.subject._id + "/course/" + id;
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          return `/resource-deleted?type=course`;
        }
        return "/courses";
      }
    };

    const getNotificationLink = async (notif: any): Promise<string> => {
      switch (notif.refType) {
        case "newHomework":
          return await getHomeworkLink(notif.ref);
        case "newCourse":
          return await getCourseLink(notif.ref);
        case "newBulletin":
          return "/bulletin";
        case "newEmploi":
          return "/emplois";
        case "StudentPunishmentAccepted":
          return "/";
        case "adminMessage":
          return "/chat";
        case "newActuality":
          return "/actualites";
        case "newComment":
          return "/actualites";
        case "newEvent":
          return "/calendar";
        case "newPayment":
          return "/paiement";
        case "newRegistration":
          return "/";
        case "attendance":
          return "/attendance";
        default:
          return "/";
      }
    };

    const updateData = async () => {
      try {
        const { data } = await ApiService.get("/notifications");
        const notificationsWithLinks = await Promise.all(
          data.notifications
            .slice(0, 5) // Take only first 5 items
            .map(async (item) => ({
              ...item,
              link: await getNotificationLink(item),
            }))
        );

        notifications.value = notificationsWithLinks;
      } catch (e) {
        console.log(e);
      }
    };

    updateData();

    const handleNotification = async (notification: any) => {
      if (notification.users.includes(parentId)) {
        updateData();
      }
    };

    const changeStudent = (activeStudent: any, redirectTo?: string) => {
      let studentYears = Object.keys(activeStudent.schoolarYearsHistory);
      const year = studentYears[studentYears.length - 1];
      window.localStorage.setItem("activeSchoolarYear", year);
      if (redirectTo) {
        window.localStorage.setItem("redirectAfterReload", redirectTo);
      }
      store.commit(
        "setCurrentYear",
        SchoolarYears.value.find((obj) => {
          return obj._id === year;
        })
      );
      store.commit("setStudent", activeStudent);
      window.location.reload();
    };

    const handleNotificationClick = (notification: any) => {
      // For notifications without student specifics or no active student
      if (
        !notification.students?.length ||
        !localStorage.getItem("activeStudent")
      ) {
        router.push(notification.link);
        return;
      }
      // Check if we need to change student
      if (
        !notification.students.some(
          (student) => student._id === localStorage.getItem("activeStudent")
        )
      ) {
        const newStudent = store.getters.currentUser.students.find(
          (student) => student._id === notification.students[0]._id
        );
        if (newStudent) {
          changeStudent(newStudent, notification.link);
          return;
        }
      }
      // If no student change needed, navigate to link
      router.push(notification.link);
    };

    onMounted(() => {
      const redirectUrl = window.localStorage.getItem("redirectAfterReload");
      if (redirectUrl) {
        window.localStorage.removeItem("redirectAfterReload");
        router.push(redirectUrl);
      }
    });

    socket.on("attendance", handleNotification);
    socket.on("newHomework", handleNotification);
    socket.on("newCourse", handleNotification);
    socket.on("newBulletin", handleNotification);
    socket.on("newEmploi", handleNotification);
    socket.on("StudentPunishmentAccepted", handleNotification);
    socket.on("adminMessage", handleNotification);
    socket.on("newActuality", handleNotification);
    socket.on("newEvent", handleNotification);
    socket.on("newPayment", handleNotification);
    socket.on("newRegistration", handleNotification);
    socket.on("newComment", handleNotification);

    onUnmounted(() => {
      socket.off("attendance");
      socket.off("newHomework");
      socket.off("newCourse");
      socket.off("newBulletin");
      socket.off("newEmploi");
      socket.off("StudentPunishmentAccepted");
      socket.off("adminMessage");
      socket.off("newActuality");
      socket.off("newEvent");
      socket.off("newPayment");
      socket.off("newRegistration");
      socket.off("newComment");
    });

    return {
      notifications,
      moment,
      t,
      handleNotificationClick,
    };
  },
});
